/* Webfont: Rebeqa-Bold */@font-face {
  font-family: 'AméliaDisplay';
  src: url('./fonts/Rebeqa/Web-TT/Rebeqa-ExtraLight.woff') format('woff'), /* Modern Browsers */
       url('./fonts/Rebeqa/Web-TT/Rebeqa-ExtraLight.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/Rebeqa/Web-TT/Rebeqa-ExtraLight.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
/* Webfont: Bergen-Mono-Bold */@font-face {
  font-family: 'Bergen-Mono';
  src: url('./fonts/BergenMono-Webfont/BergenMono-Bold.woff') format('woff'), /* Modern Browsers */
       url('./fonts/BergenMono-Webfont/BergenMono-Bold.woff2') format('woff2'); /* Modern Browsers */
           font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'paralines';
  src: url('./fonts/Paralines-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'busoroma';
  src: url('./fonts/ITC\ Busorama\ Bold.otf') format('opentype');
}

body {
  /* margin: 0; */
  font-family: 'impact';
  background-color: rgb(249, 203, 7);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
